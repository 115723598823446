import './App.css';
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import {useNavigate} from 'react-router-dom';

import battleButton from './assets/images/battle.png';
import partyButton from './assets/images/party.png';
import howToBuyButton from './assets/images/howToBuy.png';
import raydiumButton from './assets/images/raydiumButton.png';

import hahaNav from './assets/images/hahaNav2.png';
import tokenSupplyNav from './assets/images/tokenSupply.png';
import taxNav from './assets/images/taxNav.png';

import statueOne from './assets/images/statue1.png';
import statueTwo from './assets/images/statue2.png';
import statueThree from './assets/images/statue3.png';
import statueFour from './assets/images/statue4.png';
import testSlide from './assets/images/phaseOne.png';
import testSlide2 from './assets/images/phaseTwo.png';
import testSlide3 from './assets/images/phaseThree.png';
import testSlide4 from './assets/images/phaseFour.png';

import unmutedImage from './assets/images/music.png';
import mutedImage from './assets/images/musicMuted.png';
import audioFile from './assets/sounds/menumusic2.mp3';

import birdeyeButton from './assets/images/birdeyeButton.png';
import solscanButton from './assets/images/solscanButton.png';
import telegramButton from './assets/images/telegramButton.png';
import twitterButton from './assets/images/twitterButton.png';

import cryingHehe from './assets/images/cryingHehe.png';
import partyHehe from './assets/images/partyHehe.png';

import Popup from './Popup';
import PopupBattle from './PopupBattle';
import PopupParty from './PopupParty';

import IntroVideo from './IntroVideo';
import introVideoSource from './assets/videos/hehehahaIntro.mp4';

import hahaTokenAddress from './assets/images/hahaTokenAddress.gif';
import hahaTotalSupply from './assets/images/hahaTotalSupply.gif';
import hahaTaxes from './assets/images/hahaTaxes.gif';
import hahaReservedSupply from './assets/images/hahaReservedSupply.gif';

import hahaSound1 from './assets/sounds/hahaSound1.mp3';
import hahaSound2 from './assets/sounds/hahaSound2.mp3';
import hahaSound3 from './assets/sounds/hahaSound3.mp3';
import hahaSound4 from './assets/sounds/hahaSound4.mp3';

import heheBoss from './assets/easterEgg/gemBoss.png';


import Test from './Test';

function App() {
  const [gemCount, setGemCount] = useState(0);
  const [showContent, setShowContent] = useState(false);

  const handleVideoEnd = () => {
      setShowContent(true);
  };

  const [isPopupOpen, setPopupOpen] = useState(false);

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  const [isPopupBattleOpen, setPopupBattleOpen] = useState(false);

  const togglePopupBattle = () => {
    setPopupBattleOpen(!isPopupBattleOpen);
  }

  const [isPopupPartyOpen, setPopupPartyOpen] = useState(false);

  const togglePopupParty = () => {
    setPopupPartyOpen(!isPopupPartyOpen);
  }

  const [isMuted, setIsMuted] = useState(true);
  const audioRef = useRef(null);

  const toggleAudio = () => {
    const audio = audioRef.current;

    if (audio) {
      audio.volume = isMuted ? 1 : 0;

      if (isMuted) {
        audio.play();
      } else {
        audio.pause();
      }

      setIsMuted(!isMuted);
    }
  };

  const [popups, setPopups] = useState([]);
  const imageSources = [hahaTokenAddress, hahaTotalSupply, hahaTaxes, hahaReservedSupply];
  const audioSources = useMemo(() => [hahaSound1, hahaSound2, hahaSound3, hahaSound4], []);

  const playSound = useCallback((index) => {
    const audio = new Audio(audioSources[index]);
    audio.play();
  }, [audioSources]);

  const showPopup = useCallback((index) => {
    if (!popups.includes(index)) {
      setPopups((prevPopups) => [...prevPopups, index]);
      playSound(index);
    }
  }, [popups, playSound]);

  useEffect(() => {
    const popupTimings = [18000, 19000, 20000, 21000];

    const timeoutIds = popupTimings.map((timing, index) =>
      setTimeout(() => showPopup(index), timing)
    );

    return () => {
      timeoutIds.forEach(clearTimeout);
    };
  }, [showPopup]);
  
  const closePopup = (index) => {
    setPopups((prevPopups) => prevPopups.filter((popupIndex) => popupIndex !== index));
  };

  const navigate = useNavigate();

  const redirectToNewPage = () => {
    if (gemCount < 420) {
      alert("Not enough gems");
    } else {
      navigate("/newpage"); 
    }
  };


  return (
    <div className="App">
      {!showContent && <IntroVideo videoSource={introVideoSource} onVideoEnd={handleVideoEnd} />}
      {showContent && (
        <div>
        
      <div className="headerContainer">
        <div className="leftHeader">
          <div className="buttonContainer">
            <img src={howToBuyButton} width='260px' alt='howToBuy' onClick={togglePopup}/>
            <a href='https://raydium.io/'>
              <img src={raydiumButton} alt='raydium button' width='260px' height='auto'/>
            </a>
          </div>
          {isPopupOpen && <Popup onClose={togglePopup} gemCount={gemCount} setGemCount={setGemCount}/>}
          
        </div>
        <div className='centerHeader'>
          
          {isPopupBattleOpen && <PopupBattle onClose={togglePopupBattle} />}
          {isPopupPartyOpen && <PopupParty onClose={togglePopupParty} />}
          
          <div className='image-wrapper'>
            <img src={hahaNav} width='275px' alt='nav haha' className='hover-image'/>
            <div className='label'>Bak9xbry5mbLj3WM67i9BQDVVZMUWkZuEmFk7QZipump</div>
          </div>
          <img src={tokenSupplyNav} width='275px' alt='nav supply' className='hover-image'/>
          <img src={taxNav} width='275px' alt='nav tax' className='hover-image'/>
        </div>
        <div className='rightHeader'>
          <img src={isMuted ? mutedImage : unmutedImage } alt={isMuted ? 'Muted' : 'Unmuted'} onClick={toggleAudio} width='100px'/>
          <audio ref={audioRef} autoPlay muted={isMuted} loop>
            <source src={audioFile} type="audio/mp3" />
          </audio>
        </div>
      </div>
      <div className='mainContainer'>
        <div className='centerContent'>
          {popups.map((index) => (
            <div key={index} className="popup" style={{ top: `${index * 100}px` }}>
              <span className="close" onClick={() => closePopup(index)}>
                &times;
              </span>
              <img src={imageSources[index]} alt={`Popup ${index + 1}`} />
            </div>
            ))}
          <div>
            <img src={battleButton} width='260px' alt='battle button' className='battleButton' onClick={togglePopupBattle}/>
            <img src={cryingHehe} alt='crying hehe' className='cryingHehe' />
            <img src={partyButton} width='260px' alt='party button' className='partyButton' onClick={togglePopupParty}/>
            <img src={partyHehe} alt='party hehe' className='partyHehe' />
          </div>
          <div className="zigzag-container">
            <div className="image-wrapper2">
              <img src={statueThree} alt="statue3" className="zigzag-image" />
              <img src={testSlide4} alt="testSlide icon" className="test-slide-image2" />
            </div>
            <div className="image-wrapper2">
              <img src={statueTwo} alt="statue2" className="zigzag-image" />
              <img src={testSlide3} alt="testSlide icon" className="test-slide-image" />
            </div>
            <div className="image-wrapper2">
              <img src={statueFour} alt="statue4" className="zigzag-image" />
              <img src={testSlide2} alt="testSlide icon" className="test-slide-image2" />
            </div>
            <div className="image-wrapper2">
              <img src={statueOne} alt="statue1" className="zigzag-image" />
              <img src={testSlide} alt="testSlide icon" className="test-slide-image" />
            </div>
          </div>
        </div>
      </div>
      <div className='heheBoss'>
        <img 
        src={heheBoss} 
        alt='420brokie' 
        className='heheBossMan'
        onClick={redirectToNewPage}/>
      </div>
      <Test gemCount={gemCount} setGemCount={setGemCount} />

      <div className='footer'>
        <a href='https://birdeye.so/'>
          <img src={birdeyeButton} width='150px' alt='birdeye' className='footerButton'/>
        </a>
        <a href='https://solscan.io/'>
          <img src={solscanButton} width='150px' alt='solscan' className='footerButton'/>
        </a>
        <a href='https://t.me/'>
          <img src={telegramButton} width='150px' alt='telegram' className='footerButton'/>
        </a>
        <a href='https://twitter.com/home'>
          <img src={twitterButton} width='150px' alt='twitter' className='footerButton'/>
        </a>
      </div>
      </div>
      )}
    </div>
  );
}

export default App;
